import React, { Component } from "react";
import items from "./data";

//import Client from "./Contentful";

const BusinessContext = React.createContext();

export default class BusinessProvider extends Component {
  state = {
    businesses: [],
    sortedBusinesses: [],
    featuredBusinesses: [],
    loading: true,
    //
    type: "all",
    capacity: 1,
    price: 0,
    minPrice: 0,
    maxPrice: 0,
    minSize: 0,
    maxSize: 0,
    breakfast: false,
    pets: false
  };

  // getData = async () => {
  //   try {
  //     let response = await Client.getEntries({
  //       content_type: "beachResortBusiness"
  //     });
  //     let business = this.formatData(response.items);

  //     let featuredBusiness = business.filter(business => business.featured === true);
  //     //
  //     let maxPrice = Math.max(...business.map(item => item.price));
  //     let maxSize = Math.max(...business.map(item => item.size));
  //     this.setState({
  //       business,
  //       featuredBusiness,
  //       sortedBusiness: business,
  //       loading: false,
  //       //
  //       price: maxPrice,
  //       maxPrice,
  //       maxSize
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  componentDidMount() {
    // this.getData();
    let businesses = this.formatData(items);
    let featuredBusinesses = businesses.filter(business => business.featured === true);
    //
    let maxPrice = Math.max(...businesses.map(item => item.price));
    let maxSize = Math.max(...businesses.map(item => item.size));
    this.setState({
      businesses,
      featuredBusinesses,
      sortedBusinesses: businesses,
      loading: false,
      //
      price: maxPrice,
      maxPrice,
      maxSize
    });
  }

  formatData(items) {
    let tempItems = items.map(item => {
      let id = item.sys.id;
      let images = item.fields.images.map(image => image.fields.file.url);

      let business = { ...item.fields, images, id };
      return business;
    });
    return tempItems;
  }
  getBusiness = slug => {
    let tempBusinesses = [...this.state.businesses];
    const business = tempBusinesses.find(business => business.slug === slug);
    return business;
  };
  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name, value);

    this.setState(
      {
        [name]: value
      },
      this.filterBusinesses
    );
  };
  filterBusinesses = () => {
    let {
      businesses,
      type,
      capacity,
      price,
      minSize,
      maxSize,
      breakfast,
      pets
    } = this.state;

    let tempBusinesses = [...businesses];
    // transform values
    // get capacity is eurphimism for years established
    capacity = parseInt(capacity);
    price = parseInt(price);
    // filter by type
    if (type !== "all") {
      tempBusinesses = tempBusinesses.filter(business => business.type === type);
    }
    // filter by capacity
    if (capacity !== 1) {
      tempBusinesses = tempBusinesses.filter(business => business.capacity >= capacity);
    }
    // filter by price
    tempBusinesses = tempBusinesses.filter(business => business.price <= price);
    //filter by size stands for sq footage
    tempBusinesses = tempBusinesses.filter(
      business => business.size >= minSize && business.size <= maxSize
    );
    //filter by breakfast is for valuation
    if (breakfast) {
      tempBusinesses = tempBusinesses.filter(business => business.breakfast === true);
    }
    //filter by pets is for equipment valuation
    if (pets) {
      tempBusinesses = tempBusinesses.filter(business => business.pets === true);
    }
    this.setState({
      sortedBusinesses: tempBusinesses
    });
  };
  render() {
    return (
      <BusinessContext.Provider
        value={{
          ...this.state,
          getBusiness: this.getBusiness,
          handleChange: this.handleChange
        }}
      >
        {this.props.children}
      </BusinessContext.Provider>
    );
  }
}
const BusinessConsumer = BusinessContext.Consumer;

export { BusinessProvider, BusinessConsumer, BusinessContext };

export function withBusinessConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <BusinessConsumer>
        {value => <Component {...props} context={value} />}
      </BusinessConsumer>
    );
  };
}
