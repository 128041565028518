import * as React from "react";

function AwardIcon(props) {
    return (
        <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            {...props}
        >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M18 9 A6 6 0 0 1 12 15 A6 6 0 0 1 6 9 A6 6 0 0 1 18 9 z" />
            <path d="M12.002 15.003l3.4 5.89L17 17.66l3.598.232-3.4-5.889M6.802 12.003l-3.4 5.89L7 17.66l1.598 3.232 3.4-5.889" />
        </svg>
    );
}

export default AwardIcon;
