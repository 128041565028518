import React, { Component } from "react";
import Title from "./Title";
import { BusinessContext } from "../context";
import Business from "./Business";
import Loading from "./Loading";
export default class FeaturedBusinesses extends Component {
  static contextType = BusinessContext;

  render() {
    let { loading, featuredBusinesses: businesses } = this.context;

    businesses = businesses.map(business => {
      return <Business key={business.id} business={business} />;
    });
    return (
      <section className="featured-businesses">
        <Title title="featured businesses" />
        <div className="featured-businesses-center">
          {loading ? <Loading /> : businesses}
        </div>
      </section>
    );
  }
}
