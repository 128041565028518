import React from 'react';
import './App.css';
import Home from "./pages/Home";
import SingleBusiness from "./pages/SingleBusiness";
import Error from "./pages/Error";
import Businesses from "./pages/Businesses";
import { Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/businesses/" component={Businesses} />
        <Route exact path="/businesses/:slug" component={SingleBusiness} />
        <Route component={Error} />

      </Switch>
    </>
  );
}
export default App;