import React from "react";
import ReactDOM from "react-dom";
//import './index.css';
import { BrowserRouter as Router } from "react-router-dom";
import { BusinessProvider } from "./context";
import App from './App';


ReactDOM.render(
  <BusinessProvider>
  <Router>
    <App />
    </Router>
  </BusinessProvider>,
   
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

